ul[aria-labelledby='products-label'] {
  padding-bottom: 60px;
}

ul[aria-labelledby='products-label'] li[data-value='dummy-button'] {
  cursor: pointer;
  background-color: rgb(87, 192, 49) !important;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  bottom: 0;
}

ul[aria-labelledby='products-label'] li[data-value='dummy-button'] button {
  width: 100%;
  height: 100%;
  padding: 0;
  color: #fff !important;
}

ul[aria-labelledby='products-label'] .Mui-selected {
  background-color: rgba(87, 192, 49, 0.08);
}

ul[aria-labelledby='products-label'] li {
  transition: 0.2s;
}

ul[aria-labelledby='products-label'] li:hover {
  background-color: rgba(87, 192, 49, 0.2);
}
ul[aria-labelledby='products-label'] .Mui-selected:hover {
  background-color: rgba(87, 192, 49, 0.5);
}

ul[aria-labelledby='products-label'] .MuiTouchRipple-root {
  display: none;
}
